import React from "react"
import "./index.scss"

export default function ScrollingHeadline() {
  return (
    <div className="scrolling">
      <div className="scrolling--inner">
        <div className="scrolling--wrapper">
          <p>I love:</p>
          <p>knitting</p>
          <p>front porches</p>
          <p>strawberry flavored things</p>
          <p>swimming</p>
          <p>relativity</p>
          <p>medium-sized dogs</p>
          <p>gabriel garcia marquez's novels</p>
          <p>dancing</p>
          <p>economic justice</p>
          <p>aimless walking</p>
          <p>blueberry pie</p>
          <p>meteor showers</p>
          <p>jasmine</p>
          <p>chamomile tea</p>
          <p>park benches</p>
          <p>canoes</p>
          <p>asymmetry</p>
          <p>fennel</p>
          <p>wheel-throwing</p>
          <p>lorrie moore</p>
          <p>fridge magnets</p>
          <p>postcards</p>
          <p>train sounds</p>
          <p>honeysuckle</p>
          <p>virginia peanuts</p>
          <p>lemonade</p>
          <p>a soundtrack for everything</p>
        </div>
      </div>
    </div>
  )
}
