import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Nav from "../components/Nav"
import ScrollingHeadline from "../components/ScrollingHeadline"
import AboutChapters from "../components/AboutChapters"

const AboutPage = ({ data }) => {
  const wordChapters = data.about.wordsBlocks
  return (
    <>
      <Layout>
        <Seo title="About" />
        <Nav />
        <AboutChapters wordChapters={wordChapters} />
      </Layout>
      <ScrollingHeadline />
    </>
  )
}

export default AboutPage

export const query = graphql`
  query {
    about: datoCmsAbout {
      wordsBlocks {
        sectionContent
        heading
        sectionId
        skillRating {
          rating
          name
        }
      }
      mainImage {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
