import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Nav = () => {
  const [mobileNav, setMobileNav] = useState(false)
  const resume = useStaticQuery(graphql`
    query resumeQuery {
      cv: allDatoCmsAsset(
        filter: { filename: { eq: "kendall-bendheim-2023-cv.pdf" } }
      ) {
        nodes {
          url
        }
      }
    }
  `)
  const CVURL = resume.cv.nodes[0].url
  return (
    <div className="nav-wrapper">
      <div className="logo">
        <Link to="/">
          <h1>Kendall Bendheim</h1>
          <span>web developer, retired magician</span>
        </Link>
      </div>
      <div
        className={`mobileBtn ${mobileNav && "open"}`}
        onClick={() => setMobileNav(!mobileNav)}
      >
        <span></span>
      </div>
      <div className={`nav ${mobileNav && "showing"}`}>
        <ul>
          <li>
            <Link to="/about">
              <span>about</span>
            </Link>
          </li>
          <li>
            <Link to="/projects">
              <span>projects</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/trip-tips">
              <span>trip tips</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/">thoughts</Link>
          </li> */}
          <li>
            <Link to={CVURL} target="_blank">
              <span>resume</span>
            </Link>
          </li>
          <li>
            <a href="mailto:kendall.bendheim@gmail.com" target="_blank">
              <span>get in touch</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Nav
